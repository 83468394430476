import {defaultSidebar, storage, defaultCookieSettings} from '../../constants/defaultValues'

import {
    CHANGE_SIDEBAR,
    SET_COOKIE_SETTINGS,
    SET_NOTIFICATION_LOG,
    SET_APP_SEEN
} from '../types'


const INIT_STATE_SIDEBAR = (localStorage.getItem(storage.sidebar) && ['', 'collapsed'].filter(x => x === localStorage.getItem(storage.sidebar)).length > 0) ? localStorage.getItem(storage.sidebar) : defaultSidebar

export const sidebar = (state = INIT_STATE_SIDEBAR, action) => {
    if (action.type === CHANGE_SIDEBAR) {
        localStorage.setItem(storage.sidebar, action.payload)
        return action.payload
    } else {
        return state
    }
}

let loc = window.location.hostname.split('.')
const LOCALE = ['localhost', 'cz'].includes(loc[loc.length - 1]) ? 'cz' : 'sk'

export const locale = (state = LOCALE) => {
    return state
}

const cookie_settings = localStorage.getItem(storage.cookie_settings)
let INIT_COOKIE_SETTINGS = cookie_settings ? JSON.parse(cookie_settings) : defaultCookieSettings
if(Object.keys(INIT_COOKIE_SETTINGS.cookies).length < Object.keys(defaultCookieSettings).length){
    INIT_COOKIE_SETTINGS = defaultCookieSettings
}

export const cookieSettings = (state = INIT_COOKIE_SETTINGS, action) => {
    if (action.type === SET_COOKIE_SETTINGS) {
        const newData = {
            cookies: action.payload,
            is_default: false
        }
        localStorage.setItem(storage.cookie_settings, JSON.stringify(newData))
        return newData
    } else {
        return state
    }
}

const notification_log = localStorage.getItem(storage.notifications)
let INIT_NOTIFICATION_LOG = notification_log ? JSON.parse(notification_log) : []

export const notificationLog = (state = INIT_NOTIFICATION_LOG, action) => {
    if (action.type === SET_NOTIFICATION_LOG) {
        const newData = [...state]
        newData.push(action.payload)
        localStorage.setItem(storage.notifications, JSON.stringify(newData))
        return newData
    } else {
        return state
    }
}

const INIT_APP_SEEN = localStorage.getItem(storage.app) || 0

export const appSeen = (state = INIT_APP_SEEN, action) => {
    if (action.type === SET_APP_SEEN) {
        const timestamp = Date.now()
        localStorage.setItem(storage.app, timestamp)
        return timestamp
    } else {
        return state
    }
}