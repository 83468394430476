import React, { Suspense } from 'react'
import {render} from 'react-dom'
import { Provider } from 'react-redux'
import {configureStore} from './redux/store'
import SuspenseFallback from "./components/SuspenseFallback"

const App = React.lazy(() => import('./App' ))

const APP = () => <Provider store={configureStore()}>
    <Suspense fallback={<SuspenseFallback/>}>
        <App />
    </Suspense>
</Provider>

const rootElement = document.getElementById('root')

render(
    <APP/>,
    rootElement
)
