import { combineReducers } from 'redux'
import toaster from './toaster/reducer'
import auth from './auth/reducer'
import info from './info/reducer'
import strings from './strings/reducer'
import item from './item/reducer'
import sequence from './sequence/reducer'
import administrators from './administrators/reducer'
import roles from './roles/reducer'
import permissions from './permissions/reducer'
import adminStrings from './adminStrings/reducer'
import filters from './filters/reducer'
import {sidebar, locale, cookieSettings, notificationLog, appSeen} from './settings/reducer'
import faqs from './faqs/reducer'
import advantages from './advantages/reducer'
import references from './references/reducer'
import documents from './documents/reducer'
import pdfDocuments from './pdfDocuments/reducer'
import pricelist from './pricelist/reducer'
import notifications from './notifications/reducer'
import orders from './orders/reducer'
import aboutUs from './aboutUs/reducer'
import appFeatures from './appFeatures/reducer'
import devices from './devices/reducer'
import deviceGroups from './deviceGroups/reducer'
import regionalRepresentatives from './regionalRepresentatives/reducer'

import enums from './enums/reducer'
import frontend from './frontend/reducer'

export default combineReducers({
  toaster,
  auth,
  info,
  strings,
  item,
  sequence,
  administrators,
  roles,
  permissions,
  enums,
  adminStrings,
  filters,
  sidebar,
  locale,
  cookieSettings,
  notificationLog,
  appSeen,
  faqs,
  advantages,
  references,
  documents,
  pdfDocuments,
  pricelist,
  notifications,
  orders,
  frontend,
  aboutUs,
  appFeatures,
  devices,
  deviceGroups,
  regionalRepresentatives,
})