import {
    REQUEST_PRICELIST,
    RECEIVE_PRICELIST,
    UPDATE_PRICELIST,
    ERROR_PRICELIST
} from '../types'
import {initialFetchState} from '../../constants/defaultValues'
import _isObject from "lodash/isObject"

export default (state = initialFetchState, action = {}) => {
    const payload = {...action.payload}
    switch (action.type) {
        case REQUEST_PRICELIST:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_PRICELIST:
            const a = ['addons', 'components', 'services', 'storages', 'evidence_products']
            a.map(item => {
                payload[item] = {...initialFetchState, data: payload[item]}
            })
            return {
                ...state,
                isFetching: false,
                data: payload,
                errors: {},
                message: action.message,
            }
        case UPDATE_PRICELIST:
            const data = state.data
            const key = action.key
            let index = null
            switch(action.updateType) {
                case 'create':
                case 'update':
                    data[key] = {...initialFetchState, data: payload}
                    break
                case 'delete':
                    index = data[key].data.items.findIndex(item => item.id == action.id)
                    data[key].data.items.splice(index, 1)
                    break
            }
            return {
                ...state,
                isFetching: false,
                data,
                errors: {}
            }
        case ERROR_PRICELIST:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: _isObject(payload) && payload.server ? payload.server : ''
            }
        default:
            return state
    }
}