import {
    REQUEST_FRONTEND,
    RECEIVE_FRONTEND,
    ERROR_FRONTEND
} from '../types'
import {initialFrontendFetchState} from '../../constants/defaultValues'

export default (state = initialFrontendFetchState, action = {}) => {
    const newState = {...state}
    switch(action.type) {
        case REQUEST_FRONTEND:
            newState[action.dataType].isFetching = true
            if(action.dataType === 'landing'){
                newState.pricelist.isFetching = true
            }
            return newState
        case RECEIVE_FRONTEND:
            newState[action.dataType] = {
                ...newState[action.dataType],
                isFetching: false,
                data: action.payload,
                message: ''
            }
            if(action.dataType === 'landing'){
                newState.pricelist = {
                    ...newState.pricelist,
                    isFetching: false,
                    data: action.payload.pricelist,
                    message: ''
                }
                delete newState.landing.data.pricelist
            }
            return newState
        case ERROR_FRONTEND:
            newState[action.dataType] = {
                ...newState[action.dataType],
                isFetching: false,
                data: [],
                message: 'unexpected_error'
            }
            if(action.dataType === 'landing'){
                newState.pricelist = {
                    ...newState.pricelist,
                    isFetching: false,
                    data: [],
                    message: 'unexpected_error'
                }
            }
            return newState
        default:
            return state
    }
}