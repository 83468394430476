/*TOASTER*/
export const SET_TOASTER = "SET_TOASTER"

/*COOKIE*/
export const SET_COOKIE_SETTINGS = "SET_COOKIE_SETTINGS"

/*APP*/
export const SET_APP_SEEN = "SET_APP_SEEN"

/*NOTIFICATION READ*/
export const SET_NOTIFICATION_LOG = "SET_NOTIFICATION_LOG"

/*SIDEBAR*/
export const CHANGE_SIDEBAR = "CHANGE_SIDEBAR"

/* FILTERS */
export const SET_FILTER_DATA = "SET_FILTER_DATA"
export const TOGGLE_FILTER = "TOGGLE_FILTER"

/* AUTH */
export const REQUEST_CURRENT_USER = "REQUEST_CURRENT_USER"
export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER"
export const ERROR_CURRENT_USER = "ERROR_CURRENT_USER"

/* INFO */
export const REQUEST_INFO = "REQUEST_INFO"
export const RECEIVE_INFO = "RECEIVE_INFO"
export const ERROR_INFO = "ERROR_INFO"

/* FRONTEND */
export const REQUEST_FRONTEND = "REQUEST_FRONTEND"
export const RECEIVE_FRONTEND = "RECEIVE_FRONTEND"
export const ERROR_FRONTEND = "ERROR_FRONTEND"

/* STRINGS */
export const REQUEST_STRINGS = "REQUEST_STRINGS"
export const RECEIVE_STRINGS = "RECEIVE_STRINGS"
export const UPDATE_STRINGS = "UPDATE_STRINGS"
export const ERROR_STRINGS = "ERROR_STRINGS"
export const TOGGLE_DEV_STRINGS = "TOGGLE_DEV_STRINGS"

/* ADMIN STRINGS */
export const REQUEST_ADMIN_STRINGS = "REQUEST_ADMIN_STRINGS"
export const RECEIVE_ADMIN_STRINGS = "RECEIVE_ADMIN_STRINGS"
export const UPDATE_ADMIN_STRINGS = "UPDATE_ADMIN_STRINGS"
export const ERROR_ADMIN_STRINGS = "ERROR_ADMIN_ROLES"

/* ADMINS */
export const REQUEST_ADMINISTRATORS = "REQUEST_ADMINISTRATORS"
export const RECEIVE_ADMINISTRATORS = "RECEIVE_ADMINISTRATORS"
export const UPDATE_ADMINISTRATORS = "UPDATE_ADMINISTRATORS"
export const ERROR_ADMINISTRATORS = "ERROR_ADMINISTRATORS"

/* ROLES */
export const REQUEST_ROLES = "REQUEST_ROLES"
export const RECEIVE_ROLES = "RECEIVE_ROLES"
export const UPDATE_ROLES = "UPDATE_ROLES"
export const ERROR_ROLES = "ERROR_ROLES"

/* PERMISSIONS */
export const REQUEST_PERMISSIONS = "REQUEST_PERMISSIONS"
export const RECEIVE_PERMISSIONS = "RECEIVE_PERMISSIONS"
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS"
export const ERROR_PERMISSIONS = "ERROR_PERMISSIONS"

/* FAQ */
export const REQUEST_FAQS = "REQUEST_FAQS"
export const RECEIVE_FAQS = "RECEIVE_FAQS"
export const UPDATE_FAQS = "UPDATE_FAQS"
export const ERROR_FAQS = "ERROR_FAQS"

/* PRICELIST */
export const REQUEST_PRICELIST = "REQUEST_PRICELIST"
export const RECEIVE_PRICELIST = "RECEIVE_PRICELIST"
export const UPDATE_PRICELIST = "UPDATE_PRICELIST"
export const ERROR_PRICELIST = "ERROR_PRICELIST"

/* ADVANTAGES */
export const REQUEST_ADVANTAGES = "REQUEST_ADVANTAGES"
export const RECEIVE_ADVANTAGES = "RECEIVE_ADVANTAGES"
export const UPDATE_ADVANTAGES = "UPDATE_ADVANTAGES"
export const ERROR_ADVANTAGES = "ERROR_ADVANTAGES"

/* DEVICES */
export const REQUEST_DEVICES = "REQUEST_DEVICES"
export const RECEIVE_DEVICES = "RECEIVE_DEVICES"
export const UPDATE_DEVICES = "UPDATE_DEVICES"
export const ERROR_DEVICES = "ERROR_DEVICES"

/* DEVICE-GROUPS */
export const REQUEST_DEVICE_GROUPS = "REQUEST_DEVICE_GROUPS"
export const RECEIVE_DEVICE_GROUPS = "RECEIVE_DEVICE_GROUPS"
export const UPDATE_DEVICE_GROUPS = "UPDATE_DEVICE_GROUPS"
export const ERROR_DEVICE_GROUPS = "ERROR_DEVICE_GROUPS"

/* ABOUT US */
export const REQUEST_ABOUT_US_ITEMS = "REQUEST_ABOUT_US_ITEMS"
export const RECEIVE_ABOUT_US_ITEMS = "RECEIVE_ABOUT_US_ITEMS"
export const UPDATE_ABOUT_US_ITEMS = "UPDATE_ABOUT_US_ITEMS"
export const ERROR_ABOUT_US_ITEMS = "ERROR_ABOUT_US_ITEMS"

/* APP FEATURES US */
export const REQUEST_APP_FEATURES = "REQUEST_APP_FEATURES"
export const RECEIVE_APP_FEATURES = "RECEIVE_APP_FEATURES"
export const UPDATE_APP_FEATURES = "UPDATE_APP_FEATURES"
export const ERROR_APP_FEATURES = "ERROR_APP_FEATURES"

/* REFERENCES */
export const REQUEST_REFERENCES = "REQUEST_REFERENCES"
export const RECEIVE_REFERENCES = "RECEIVE_REFERENCES"
export const UPDATE_REFERENCES = "UPDATE_REFERENCES"
export const ERROR_REFERENCES = "ERROR_REFERENCES"

/* REGIONAL_REPRESENTTIVES */
export const REQUEST_REGIONAL_REPRESENTATIVES = "REQUEST_REGIONAL_REPRESENTATIVES"
export const RECEIVE_REGIONAL_REPRESENTATIVES = "RECEIVE_REGIONAL_REPRESENTATIVES"
export const UPDATE_REGIONAL_REPRESENTATIVES = "UPDATE_REGIONAL_REPRESENTATIVES"
export const ERROR_REGIONAL_REPRESENTATIVES = "ERROR_REGIONAL_REPRESENTATIVES"

/* DOCUMENTS */
export const REQUEST_DOCUMENTS = "REQUEST_DOCUMENTS"
export const RECEIVE_DOCUMENTS = "RECEIVE_DOCUMENTS"
export const UPDATE_DOCUMENTS = "UPDATE_DOCUMENTS"
export const ERROR_DOCUMENTS = "ERROR_DOCUMENTS"

/* NOTIFICATIONS */
export const REQUEST_NOTIFICATIONS = "REQUEST_NOTIFICATIONS"
export const RECEIVE_NOTIFICATIONS = "RECEIVE_NOTIFICATIONS"
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS"
export const ERROR_NOTIFICATIONS = "ERROR_NOTIFICATIONS"

/* ORDERS */
export const REQUEST_ORDERS = "REQUEST_ORDERS"
export const RECEIVE_ORDERS = "RECEIVE_ORDERS"
export const ERROR_ORDERS = "ERROR_ORDERS"

/* PDF-DOCUMENTS */
export const REQUEST_PDF_DOCUMENTS = "REQUEST_PDF_DOCUMENTS"
export const RECEIVE_PDF_DOCUMENTS = "RECEIVE_PDF_DOCUMENTS"
export const UPDATE_PDF_DOCUMENTS = "UPDATE_PDF_DOCUMENTS"
export const ERROR_PDF_DOCUMENTS = "ERROR_PDF_DOCUMENTS"

/* ENUMS */
export const REQUEST_ENUMS = "REQUEST_ENUMS"
export const RECEIVE_ENUMS = "RECEIVE_ENUMS"
export const RELOAD_ENUMS = "RELOAD_ENUMS"
export const ERROR_ENUMS = "ERROR_ENUMS"

/* ITEM */
export const REQUEST_ITEM = "REQUEST_ITEM"
export const RECEIVE_ITEM = "RECEIVE_ITEM"
export const UPDATE_ITEM = "UPDATE_ITEM"
export const ERROR_ITEM = "ERROR_ITEM"

/* SEQUENCE */
export const REQUEST_SEQUENCE = "REQUEST_SEQUENCE"
export const RECEIVE_SEQUENCE = "RECEIVE_SEQUENCE"
export const ERROR_SEQUENCE = "ERROR_SEQUENCE"