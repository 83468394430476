/*
LocalStorage keys
*/
export const storage = {
    authentication: 'Authorization_' + btoa(window.location.host),
    sidebar: '__sidebar',
    cookie_settings: '__cs',
    notifications: '__ns',
    app: '__app',
}
export const defaultSidebar = ''
export const localeOptions = ['cs-CZ', 'sk-SK']

//cookie disclaimer
export const defaultCookieSettings = {
    is_default: true,
    cookies: {
        necessary: true,
        analytics: false,
        marketing: false
    }
}

// Toaster
export const defaultToaster = {
    toasterType: "OK",
    title: '',
    message: '',
    counter: 0
}

//fetch state
export const initialFetchState = {
    data: {},
    isFetching: false,
    shouldReload: false,
    errors: {},
    message: ''
}

//fetch sequence state
export const initialSequenceFetchState = {
    data: [],
    isFetching: false,
    errors: {},
    message: ''
}

export const initialFrontendFetchState = {
    'landing': {
        data: [],
        isFetching: false
    },
    'news': {
        data: [],
        isFetching: false
    },
    'faqs': {
        data: [],
        isFetching: false
    },
    'about-us': {
        data: {},
        isFetching: false
    },
    'app-features': {
        data: [],
        isFetching: false
    },
    'documents': {
        data: {},
        isFetching: false
    },
    'pricelist': {
        data: {},
        isFetching: false
    },
    'notifications': {
        data: {},
        isFetching: false
    },
}

//enum fetch state
export const initialEnumFetchState = {
    'filter-boolean': {
        data: [
            {value: 1, label: 'yes'},
            {value: 0, label: 'no'},
            {value: null, label: 'any'},
        ],
        isFetching: false,
        shouldReload: false
    },
    'advantage-icons': {
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((i) => ({value: i, label: i + '.svg'})),
        isFetching: false,
        shouldReload: false
    },
    'about-us-icons': {
        data: [1, 2, 3, 4, 5].map((i) => ({value: i, label: i + '.svg'})),
        isFetching: false,
        shouldReload: false
    },
    'app-features-icons': {
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map((i) => ({value: i, label: i + '.svg'})),
        isFetching: false,
        shouldReload: false
    },
    'units': {
        data: [
            {value: 'month', label: 'month'},
            {value: 'month2', label: 'month-single'},
            {value: 'doctor', label: 'doctor'},
            {value: 'km', label: 'km'},
            {value: 'piece', label: 'piece'},
            {value: 'hour', label: 'hour'}
        ],
        isFetching: false,
        shouldReload: false
    },
    'pdf-actions': {
        data: {
            download: {value: 'download', label: 'download'},
            show: {value: 'show', label: 'show'},
        },
        isFetching: false,
        shouldReload: false
    },
    'order-types': {
        data: {
            order: {value: 'order', label: 'order'},
            demo: {value: 'demo', label: 'demo'},
        },
        isFetching: false,
        shouldReload: false
    },
    'reg-rep-types': {
        data: {
            0: {value: 0, label: 'premium'},
            1: {value: 1, label: 'sales'},
        },
        isFetching: false,
        shouldReload: false
    },
    'products': {
        data: {
            global: {value: 'global', label: 'product-global'},
            ambulance: {value: 'ambulance', label: 'product-ambulance'},
            stomatolog: {value: 'stomatolog', label: 'product-stomatolog'},
        },
        isFetching: false,
        shouldReload: false
    }
}

//enum fetch Item state
export const initialEnumItemFetchState = {
    data: [],
    isFetching: false,
    shouldReload: false
}

//user state
export const initialUserFetchState = {
    isAuthenticated: false,
    isFetching: false,
    user: {},
}

